import React, { useState, useEffect } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import Head from '../components/head';
import Layout from '../components/layout';
import BlogFilterControls from '../components/blogFilterControls';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Paginate from '../components/paginate';

const BlogPage = () => {
  const PAGE = "Blog";
  const baseSiteUrl = 'https://authorseanallen.com'
  const ITEMS_PER_PAGE = 10;
  const PAGE_NEIGHBORS = 2;
  const MONTHS = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ]

  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost (
        sort: {
          fields: publishedDate,
          order: DESC
        }
      ) {
        edges {
          node {
            title
            slug
            tags
            publishedDate( formatString:"MMMM Do, YYYY")
            body {
              json
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    // setContentDivHeight();
    // addScrollToTagLinks();
    // scrollYoShitRight();
  });

  const options = {
    renderNode: {
      "embedded-asset-block": (node) => {
        const nodeRoot = node.data.target.fields;
        const alt = nodeRoot.title['en-US'];
        const url = nodeRoot.file['en-US'].url;
        return <img src={url} alt={alt} />
      }
    }
  }


  //* Set visible posts state to all posts at first
  const allPosts = data.allContentfulBlogPost.edges;
  const allPostsCopy = allPosts.slice();

  const [filteredPosts, setFilteredPosts] = useState(allPostsCopy);
  const [paginatedPosts, setPaginatedPosts] = useState([]);
  const [totalPages, setTotalPages] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [activeFilter, setActiveFilter] = useState({ type: 'All Posts' });
  const [containerHeight, setContainerHeight] = useState(0);

  const setContentDivHeight = () => {
    const containerDiv = document.querySelector('.hero');
    const containerHeight = containerDiv.getBoundingClientRect().height;
    setContainerHeight(containerHeight);
  }

  // * Replaced by scrollToTop method in paginate.js - 6/04/21
  // const addScrollToTagLinks = () => {
  //   const tagLinks = document.querySelectorAll('.tag-link');
  //   tagLinks.forEach(tag => {
  //     tag.addEventListener('click', scrollPostList);
  //   })
  // }

  // const scrollPostList = () => {
  //   document.querySelector('.page-header-info').scrollIntoView({
  //     behavior: 'smooth'
  //   })
  // }
  //* *****************


  const allTags = buildTags();

  //* Example of function hoisting in JS right here.
  //* This function is called above before it's declared.
  //* You can't do that with the const name = () => syntax.
  function buildTags () {
    let tags = [];

    allPosts.map(post => {
      //* post.node.tags is an array,
      //* so spread its arguments into tags.
      tags.push(...post.node.tags);
    });

    //* Dedupe tags
    const cleanedTags = tags.filter((tag, idx) => {
      return tags.indexOf(tag) === idx;
    });

    return cleanedTags;
  }

  const filterByDate = (date = new Date()) => {
    setStartDate(date);
    const month = MONTHS[date.getMonth()];
    const year = date.getFullYear();
    const filtered = allPosts.filter(post => {
      return post.node.publishedDate.includes(month) && post.node.publishedDate.includes(year);
    });

    setActiveFilter({ type: 'Date', month: month, year: year });
    setFilteredPosts(filtered);
    resetPagination(filtered);
  }

  const setTitleFilter = (evt) => {
    const input = evt.target.value;
    const filtered = allPosts.filter(post => {
      return post.node.title.toLowerCase().includes(input.toLowerCase());
    });

    setActiveFilter({ type: 'Title', title: input });
    setFilteredPosts(filtered)
    resetPagination(filtered);
  }

  const setCategoryFilter = (evt) => {
    evt.preventDefault();
    const category = evt.target.innerText;
    const filtered = allPosts.filter(post => {
      return post.node.tags.includes(category);
    });

    setActiveFilter({ type: 'Category', category: category });
    setFilteredPosts(filtered);
    resetPagination(filtered);
  }

  const handleClearFilter = () => {
    setFilteredPosts(allPostsCopy);
    resetPagination(allPostsCopy);
    setActiveFilter({ type: 'All Posts', title: '' });
  }

  const handleClearTitleSearch = () => {
    setFilteredPosts(allPostsCopy);
    resetPagination(allPostsCopy);
    setActiveFilter({ type: 'Title', title: '' });
  }

  const toggleFilter = (selectedFilter) => {
    setFilteredPosts(allPostsCopy);
    resetPagination(allPostsCopy);
    setActiveFilter({ type: `${selectedFilter}` });
  }

  const onPageChanged = data => {
    const { currentPage, totalPages, itemsPerPage } = data;
    const offset = (currentPage - 1) * itemsPerPage;
    const paginatedPosts = filteredPosts.slice(offset, offset + itemsPerPage);

    setTotalPages(totalPages);
    setPaginatedPosts(paginatedPosts);
  }

  const resetPagination = (filteredPosts) => {
    const currentPage = 1;
    const itemsPerPage = ITEMS_PER_PAGE;
    const offset = (currentPage - 1) * itemsPerPage;
    // console.log(`offset: ${offset}, itemsPerPage: ${itemsPerPage}`);
    const paginatedPosts = filteredPosts.slice(offset, offset + itemsPerPage);

    setPaginatedPosts(paginatedPosts);
  }

  const renderContentfulBlog = (posts) => {
    //* When filtering returns nothing,
    //* we still want the page title to be 'Blog'.
    if (posts.length === 0) {
      return <Head title={PAGE} />
    }

    return posts.map((post, idx) => {
      return (
        <React.Fragment key={idx}>
          <Head title={PAGE} />
          <Helmet>
            <meta name="description"
              content="Blogging home of genre fiction author Sean Allen." />
            <link rel="canonical" href={`${baseSiteUrl}/blog`} />
          </Helmet>
          <li className="post">
            <h3>
              <Link className="link-to-post" to={`/blog/${post.node.slug}`}>
                {post.node.title}
              </Link>
            </h3>
            <p>{post.node.publishedDate}</p>
            <p className="blog-snippet">
              {documentToReactComponents(post.node.body.json, options)}
            </p>
            <section className="blog-snippet-meta">
              <Link className="read-more-link link-to-post" to={`/blog/${post.node.slug}`}>
                Read more
              </Link>
              <p>
                :: Posted in {renderTagsPerPost(post.node.tags, post.node.slug)}
              </p>
            </section>
          </li>
        </React.Fragment>
      )
    })
  }

  const renderTagsPerPost = (tags) => {
    return tags.map((tag, idx) => {
      return (
        <React.Fragment key={idx}>
          <a onClick={(evt) => setCategoryFilter(evt)}
            className="link-look-a-like tag-link"
            href="#">{tag}
          </a>
          <span className="category-separator"> : </span>
        </React.Fragment>
      )
    })
  }

  const renderTags = () => {
    return allTags.map((tag, idx) => {
      return (
        <React.Fragment key={idx}>
          <a onClick={(evt) => setCategoryFilter(evt)}
            className="link-look-a-like tag-link"
            href="#">{tag}
          </a>
          <span className="category-separator">:</span>
        </React.Fragment>
      )
    })
  }

  const currentFilter = activeFilter.type;
  const activeDateFilter = currentFilter === 'Date';
  const activeCatFilter = currentFilter === 'Category';
  const activeTitleFilter = currentFilter === 'Title';


  return (
    <Layout>
      <Head />
      {/* <div className="container blog-wrapper" style={{ height: `${containerHeight}px` }}> */}
      <div className="container blog-wrapper">
        <section className="hero dark">
          <div className="page-header-info">
            <h3 className="page-title">Latest News from Sean</h3>
            <hr />
          </div>
          <div className="grid-box">
            <div className="sidebar-column">
              <div className="sidebar-contents">
                <BlogFilterControls
                  activeFilter={activeFilter}
                  setActiveFilter={toggleFilter}
                  filterByDate={filterByDate}
                  clearFilter={handleClearFilter}
                  postCount={filteredPosts.length}
                />
                <section className="tags-container">
                  <h2 className="filter-title category">Filter by Category</h2>
                  {renderTags()}
                  <hr />
                </section>
              </div>
            </div>
            <section className="post-list">
              <ol className="posts">
                {renderContentfulBlog(paginatedPosts)}
              </ol>
              <Paginate
                totalItems={filteredPosts.length}
                itemsPerPage={ITEMS_PER_PAGE}
                pageNeighbors={PAGE_NEIGHBORS}
                onPageChanged={onPageChanged}
                activeFilter={activeFilter}
              />
            </section>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default BlogPage;