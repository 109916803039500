import React from 'react';

const BlogFilterControls = ({
  activeFilter,
  postCount,
  setActiveFilter,
  clearFilter,
  filterByDate
}) => {
  return (
    <section className="filter-controls">
      <div className="current-active-filter">Active filter: {`${activeFilter.type}`}
        {
          activeFilter.type === 'Date' &&
          <span> &raquo; {activeFilter.month} &raquo; {activeFilter.year}</span>
        }
        {
          activeFilter.type === 'Title' && !(activeFilter.title) &&
          <span>{activeFilter.title}</span>
        }
        {
          activeFilter.type === 'Title' && (activeFilter.title) &&
          <span> &raquo; {activeFilter.title}</span>
        }

        {
          activeFilter.type === 'Category' && !(activeFilter.category) &&
          <span>{activeFilter.category}</span>
        }

        {
          activeFilter.type === 'Category' && (activeFilter.category) &&
          <span> &raquo; {activeFilter.category}</span>
        }

      </div>
      <div className="current-active-filter post-count">Posts: {`${postCount}`}</div>
      <button onClick={() => clearFilter()} className={activeFilter.type === 'Category' ? "btn yellow sm" : "btn yellow sm disabled"}>Clear Category</button>
      <hr className="yellow-hr" />
    </section >
  )
}

export default BlogFilterControls