import React, { Component } from 'react';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
}

class Paginate extends Component {
  constructor(props) {
    super(props);
    const { totalItems, itemsPerPage, pageNeighbors } = props;

    this.itemsPerPage = itemsPerPage;
    this.totalItems = totalItems;
    this.pageNeighbors = pageNeighbors;

    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);

    this.state = {
      currentPage: 1,
      totalItems: totalItems
    }
  }

  componentDidMount () {
    this.gotoPage(1);
  }

  componentDidUpdate (prevProps, prevState) {
    this.totalItems = this.props.totalItems;
    this.totalPages = this.totalPages = Math.ceil(this.totalItems / this.props.itemsPerPage);

    if (prevState.totalItems !== this.totalItems) {
      this.setState({ totalItems: this.totalItems });
    }

    if (prevProps.activeFilter !== this.props.activeFilter) {
      this.gotoPage(1);
    }
  }

  gotoPage = page => {
    // TODO: Look at this syntax a bit. Strange.
    const { onPageChanged = f => f } = this.props;

    // TODO: Don't understand why a calculation is necessary here...
    const currentPage = Math.max(0, Math.min(page, this.totalPages));

    const paginationData = {
      currentPage,
      totalPages: this.totalPages,
      totalItems: this.totalItems,
      itemsPerPage: this.itemsPerPage,
    }

    this.setState({ currentPage }, () => onPageChanged(paginationData));
    this.scrollToTop();
  }

  scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  handleClick = page => evt => {
    evt.preventDefault();
    this.gotoPage(page);
  }

  handleMoveLeft = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage - (this.pageNeighbors * 2) - 1);
  }

  handleMoveRight = evt => {
    evt.preventDefault();
    this.gotoPage(this.state.currentPage + (this.pageNeighbors * 2) + 1);
  }

  fetchPageNumbers = () => {
    const totalPages = this.totalPages;
    const currentPage = this.state.currentPage;
    const pageNeighbors = this.pageNeighbors;

    /*
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */

    //* PageNeighbors * 2 = numbers to either side of current page.
    //* + 3 = {first page number [1]} << {current page number [2]} >> {last page number [3]}
    const totalNumbers = (this.pageNeighbors * 2) + 3; //* + 2 for {<<} {>>}
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbors);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbors);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = (totalPages - endPage) > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        //* handle: (1) < {5 6} [7] {8 9} (10)
        case (hasLeftSpill && !hasRightSpill): {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        //* handle: (1) {2 3} [4] {5 6} > (10)
        case (!hasLeftSpill && hasRightSpill): {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        //* handle: (1) < {4 5} [6] {7 8} > (10)
        case (hasLeftSpill && hasRightSpill):
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];

    }

    return range(1, totalPages);

  }

  render () {
    if (!this.totalItems || this.totalPages === 1) return null;
    const { currentPage } = this.state;
    const pages = this.fetchPageNumbers();

    return (
      <nav aria-label="Pagination" className="pagination-wrapper">
        <ul className="pagination">
          {pages.map((page, index) => {

            if (page === LEFT_PAGE) return (
              <li key={index} className="page-item">
                <a className="page-link" href="#" aria-label="Previous" onClick={this.handleMoveLeft}>
                  <span aria-hidden="true">&laquo;</span>
                  {/* <span className="sr-only">Previous</span> */}
                </a>
              </li>
            );

            if (page === RIGHT_PAGE) return (
              <li key={index} className="page-item">
                <a className="page-link" href="#" aria-label="Next" onClick={this.handleMoveRight}>
                  <span aria-hidden="true">&raquo;</span>
                  {/* <span className="sr-only">Next</span> */}
                </a>
              </li>
            );

            return (
              <li key={index} className={`page-item${currentPage === page ? ' active' : ''}`}>
                <a className="page-link" href="#" onClick={this.handleClick(page)}>{page}</a>
              </li>
            );

          })}

        </ul>
      </nav>
    );

  }

}

export default Paginate;